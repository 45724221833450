@import url(https://fonts.googleapis.com/css?family=Pacifico|Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Orbitron&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
}

html {
  height: 100%;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.heightAll {
  height: 100%;
}

.demo {
  margin: 0 auto;
  width: 100%;
}

.footerMobile {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  background-color: rgb(0, 0, 0, 0.8);
}

.footer {
  right: 0;
  bottom: 0;
  left: 0;
}



